<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="商品名" prop="goodsName">
                <a-input v-model="queryParam.goodsName" placeholder="请输入商品名" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="shopify链接" prop="shopifyUrl">
                <a-input v-model="queryParam.shopifyUrl" placeholder="请输入shopify链接" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="商品价格" prop="goodsPrice">-->
<!--                <a-input v-model="queryParam.goodsPrice" placeholder="请输入商品价格" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="商品图片" prop="goodsCover">
                  <a-input v-model="queryParam.goodsCover" placeholder="请输入商品图片" allow-clear/>
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24">
                <a-form-item label="短剧id" prop="videoId">
                  <a-input v-model="queryParam.videoId" placeholder="请输入短剧id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否禁用 0-否 1-是" prop="isBan">
                  <a-input v-model="queryParam.isBan" placeholder="请输入是否禁用 0-否 1-是" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:user:add']">
          <a-icon type="plus" />新增
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:user:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:user:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:user:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot="isBan" slot-scope="text, record">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="record.isBan == 0" @change="changeIsOpen($event,record)"/>
        </span>


        <img slot="goodsCover" slot-scope="text, record" style="width: 48px;height: 48px;" :preview="record.goodsCover" v-image-preview :src="record.goodsCover">

        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:user:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:user:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['user:user:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['user:user:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUser,listUser, delUser,changeIsOpen } from '@/api/goods/goods'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'User',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        goodsName: null,
        goodsPrice: null,
        goodsCover: null,
        shopifyUrl: null,
        videoId: null,
        isBan: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '商品id(在shopify商品描述中填入)',
          dataIndex: 'id',
          ellipsis: true,
          width: '20%',
          align: 'center'
        },
        {
          title: '商品名',
          dataIndex: 'goodsName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '商品价格',
          dataIndex: 'goodsPrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '商品图片',
          dataIndex: 'goodsCover',
          ellipsis: true,
          scopedSlots: { customRender: 'goodsCover' },
          align: 'center'
        },
        {
          title: 'shopify链接',
          dataIndex: 'shopifyUrl',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '短剧',
          dataIndex: 'videoName',
          ellipsis: true,
          align: 'center'
        },


        {
          title: '是否禁用',
          dataIndex: 'isBan',
          width: '10%',
          align: 'center',
          scopedSlots: { customRender: 'isBan' },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          // ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询商品列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    changeIsOpen (e,record) {
      record.isBan = e ? 0 : 1
      changeIsOpen(record).then(response => {
        this.$message.success(
          '操作成功'
        )
        return  record.isBan = e ? 0 : 1
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        goodsName: undefined,
        goodsPrice: undefined,
        goodsCover: undefined,
        shopifyUrl: undefined,
        videoId: undefined,
        isBan: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/goods/export', {
            ...that.queryParam
          }, `商品_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
