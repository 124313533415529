import request from '@/utils/request'


// 查询商品列表
export function listUser(query) {
  return request({
    url: '/user/goodss/list',
    method: 'get',
    params: query
  })
}

// 查询商品分页
export function pageUser(query) {
  return request({
    url: '/user/goodss/page',
    method: 'get',
    params: query
  })
}

// 查询商品详细
export function getUser(data) {
  return request({
    url: '/user/goodss/detail',
    method: 'get',
    params: data
  })
}

// 新增商品
export function addUser(data) {
  return request({
    url: '/user/goodss/add',
    method: 'post',
    data: data
  })
}

// 修改商品
export function updateUser(data) {
  return request({
    url: '/user/goodss/edit',
    method: 'post',
    data: data
  })
}

// 删除商品
export function delUser(data) {
  return request({
    url: '/user/goodss/delete',
    method: 'post',
    data: data
  })
}

export function changeIsOpen(data) {
  return request({
    url: '/user/goodss/changeIsOpen',
    method: 'post',
    data: data
  })
}
