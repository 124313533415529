<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="商品名" prop="goodsName">
        <a-input v-model="form.goodsName" placeholder="请输入商品名"/>
      </a-form-model-item>
      <a-form-model-item label="商品价格" prop="goodsPrice">
        <a-input v-model="form.goodsPrice" placeholder="请输入商品价格"/>
      </a-form-model-item>

      <a-form-model-item label="商品图片" prop="goodsCover">
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.goodsCover"
          :showUploadList="false"
          action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"
          :beforeUpload="beforeUpload"
          :customRequest="handleChange"
        >
          <plus-outlined/>
          <img v-if="form.goodsCover" :src="form.goodsCover" alt="logo"
               style="height:104px;max-width:300px"/>
          <div v-else>

            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>


      <a-form-model-item label="展示类型" prop="type">
        <a-select v-model="form.type" @change="editType" placeholder="请选择展示类型">
          <a-select-option :key="0">短剧展示</a-select-option>
          <a-select-option :key="1">剧集展示</a-select-option>
        </a-select>
      </a-form-model-item>





      <a-form-model-item label="shopify链接" prop="shopifyUrl">
        <a-input v-model="form.shopifyUrl" placeholder="请输入shopify链接"/>
      </a-form-model-item>
      <!--      <a-form-model-item label="短剧" prop="videoId" >-->
      <!--        <a-input v-model="form.videoId" placeholder="请输入短剧id" />-->
      <!--      </a-form-model-item>-->

      <a-form-model-item label="短剧名称" prop="videoId">
        <a-select
          v-model="form.videoId"
          showSearch
          style="width: 100%"
          placeholder="请选择短剧"
          @change="getGatherList"
          allow-clear
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.videoName" v-for="(item,index) in listAll">
            {{ item.videoName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="赠送钻石数" prop="giveNum">
        <a-input-number :min="0" :step="0" v-model="form.giveNum" placeholder="请输入赠送钻石数"/>
      </a-form-model-item>

      <div v-if="this.type==1">


        <a-form-model-item label="剧集" prop="gatherId" >
          <!--        <a-input v-model="form.videoId" placeholder="请输入短剧id" />-->
          <a-select
            v-model="form.gatherId"
            showSearch
            style="width: 100%"
            placeholder="请选择剧集"
            allow-clear
            optionFilterProp="children"
          >
            <a-select-option :value="item.id" :key="item.id" :title="item.serialNumber" v-for="(item,index) in gatherList">
              第{{item.serialNumber}}集
            </a-select-option>
          </a-select>
        </a-form-model-item>



        <a-form-model-item label="剧集开始展示秒数" prop="startTime">
          <a-input-number :min="0" :step="0"  v-model="form.startTime" placeholder="请输入剧集开始展示秒数"/>
        </a-form-model-item>

        <a-form-model-item label="商品展示秒数" prop="shoTime">
          <a-input-number :min="0" :step="0" v-model="form.shoTime" placeholder="请输入商品展示秒数"/>
        </a-form-model-item>
      </div>


      <!--      <a-form-model-item label="是否禁用 0-否 1-是" prop="isBan" >-->
      <!--        <a-input v-model="form.isBan" placeholder="请输入是否禁用 0-否 1-是" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="备注" prop="remark" >-->
      <!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
      <!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getUser, addUser, updateUser} from '@/api/goods/goods'
import {listVideo} from "@/api/video/video";
import {uploadCos} from "@/api/common";
import UploadFileToVOD from "@/api/vod";
import {getGatherList} from "@/api/video/publicize";

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      uploadLoading: false,
      type: 0,
      listAll: [],
      gatherList: [],
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        goodsName: null,

        goodsPrice: null,
        giveNum: null,

        goodsCover: null,

        shopifyUrl: null,
        type: null,
        shoTime: null,
        startTime: null,

        videoId: null,

        isBan: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {}
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    beforeUpload: function (file) {
      var fileType = file.type;
      if (fileType.indexOf('image') < 0) {
        this.$message.warning('请上传图片');
        this.uploadLoading = false
        return false;
      }
      this.form.goodsCover = ""
      //TODO 驗證文件大小
    },
    getGather() {
      listVideo().then(res => {
        this.listAll = res.data;
      })
    },

    editType(value) {
      this.type = value;
    },


    handleChange(info) {

      let that = this
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          that.form.goodsCover = fileName[0];//文件名
          that.uploadLoading = false;
        },

      });
      that.uploadLoading = false;

    },

    getGatherList(value) {
      getGatherList({"id": value}).then(res => {
        this.gatherList = res.data;
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        goodsName: null,
        goodsPrice: null,
        goodsCover: null,
        shopifyUrl: null,
        videoId: null,
        isBan: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if (this.$refs.form) {
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.getGather()
      this.formType = 1
      this.type = 0
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.getGather()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({"id": id}).then(response => {
        this.form = response.data
        this.type=this.form.type
        this.getGatherList(this.form.videoId)
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
